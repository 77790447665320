<template xmlns:flex-wrap="http://www.w3.org/1999/xhtml">
    <v-scale-screen width="1920" height="1080" full-screen style="background: #090d26;">
        <div class="home">
            <Header></Header>
            <div class="content">
                <div class="content-left">
                    <div class="content-left-inbox">
                        <div class="content-left-background-img">
                            <h4 class="item-title">{{ nowDate }}</h4>
                            <div class="road-photo-list">
                                <!--              <ul class="road-photo-list-wrap">-->
                                <!--                <li v-for="(item,i) in banners ">-->
                                <!--                  <img :src="item.imageUrl"/>-->
                                <!--                </li>-->
                                <!--              </ul>-->
                                <div class="content-right-details">
                                    <div>
                                        <span></span>
                                        <span>{{ weatherData.cityName }}</span>
                                        <span>城市</span>
                                    </div>
                                    <div>
                                        <span></span>
                                        <span>{{ weatherData.temperature }}℃</span>
                                        <span>{{ weatherData.weather }}</span>
                                    </div>
                                    <div>
                                        <span></span>
                                        <span>{{ weatherData.humidity }}</span>
                                        <span>湿度</span>
                                    </div>
                                    <div>
                                        <span></span>
                                        <span>{{ weatherData.visibility }}</span>
                                        <span>可见度</span>
                                    </div>
                                    <div>
                                        <span></span>
                                        <span>{{ weatherData.windScale }}级</span>
                                        <span>{{ weatherData.windDirection }}风</span>
                                    </div>
                                    <div>
                                        <span></span>
                                        <span>{{ weatherData.pm25 }}</span>
                                        <span>PM2.5</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="content-left-background-img">
                            <h4 class="item-title">步道年龄段信息</h4>
                            <div class="access-trend" id="agetrend">

                            </div>
<!--                            <div class="road-basic-infor">-->
<!--                                <div class="road-basic-name">-->
<!--                                    &lt;!&ndash;                <p>步道名称</p>&ndash;&gt;-->
<!--                                    <p>{{ max.itemName }}</p>-->
<!--                                </div>-->
<!--                                <div class="road-basic-ct">-->
<!--                                    <div class="road-basic-left">-->
<!--                                        {{ max.distance }}-->
<!--                                    </div>-->
<!--                                    <div>被走过的公里数</div>-->
<!--                                </div>-->
<!--                                <div class="road-basic-ct">-->
<!--                                    <div class="road-basic-right">-->
<!--                                        {{ max.cycles }}-->
<!--                                    </div>-->
<!--                                    <div>绕地球的圈数</div>-->
<!--                                </div>-->
<!--                            </div>-->
                        </div>
                        <div class="content-left-background-img" style="margin-bottom: 0">
                            <h4 class="item-title">步道访问量趋势图</h4>
                            <div class="access-trend" id="bdtrend">

                            </div>
                        </div>
                    </div>
                </div>
                <div class="content-center">
                    <div class="content-center-head">
                        <div class="content-detail-left">
                            <h6>当日人数</h6>
                            <p class="content-detail-today">{{ flow.dayCount }}</p>
                        </div>
                        <div class="content-detail-center">
                            <h6>总人数</h6>
                            <p class="content-detail-all">{{ flow.totalCount }}</p>
                        </div>
                        <!--                    <div class="content-detail-right">
                                                <button id="export">
                                                    <img src="../assets/homeImg/daochu.png" alt/>
                                                    导出
                                                </button>
                                            </div>-->
                    </div>
                    <div class="content-center-box" style="margin-top: -4%;">
                        <div class="overall-display" style="display: flex" id="image_default">
                            <!--             <img src="../assets/overallstatusImg/roadmap_data.png" style="object-fit:contain;width: 100%;height: 100%;"/>-->
                            <!--            <img src="./img/img.png" alt="img.png" onerror="this.src='./img/defaultImg.png'"/>-->
                            <img :src="itemImg" style="display:block;width: 100%;height: 100%;"/>
                        </div>
                        <div class="overall-display" style="display: none" id="image_double">
                            <!--             <img src="../assets/overallstatusImg/roadmap_data.png" style="object-fit:contain;width: 100%;height: 100%;"/>-->
                            <!--            <img src="./img/img.png" alt="img.png" onerror="this.src='./img/defaultImg.png'"/>-->
                            <img :src="itemImg1" style="display:block;width: 100%;height: 50%;"/>
                            <img :src="itemImg2" style="display:block;width: 100%;height: 50%;"/>
                        </div>
                        <div class="overall-display" style="display: none;flex-wrap: wrap" id="image_third">
                            <!--             <img src="../assets/overallstatusImg/roadmap_data.png" style="object-fit:contain;width: 100%;height: 100%;"/>-->
                            <!--            <img src="./img/img.png" alt="img.png" onerror="this.src='./img/defaultImg.png'"/>-->
                            <img :src="itemImg1" style="display:flex;width: 50%;height: 50%;"/>
                            <img :src="itemImg2" style="display:flex;width: 50%;height: 50%;"/>
                            <img :src="itemImg3" style="display:flex;width: 100%;height: 50%;"/>
                        </div>
                        <div class="overall-display" style="display: none;flex-wrap: wrap" id="image_fourth">
                            <!--             <img src="../assets/overallstatusImg/roadmap_data.png" style="object-fit:contain;width: 100%;height: 100%;"/>-->
                            <!--            <img src="./img/img.png" alt="img.png" onerror="this.src='./img/defaultImg.png'"/>-->
                            <img :src="itemImg1" style="display:flex;width: 50%;height: 50%;"/>
                            <img :src="itemImg2" style="display:flex;width: 50%;height: 50%;"/>
                            <img :src="itemImg3" style="display:flex;width: 50%;height: 50%;"/>
                            <img :src="itemImg4" style="display:flex;width: 50%;height: 50%;"/>
                        </div>
                    </div>
                </div>
                <div class="content-right">
                    <div class="content-right-inbox">

                        <div class="content-right-background-img">
                            <!--            <h4 class="item-title">步道总数：{{num}}</h4>-->
                            <h4 class="item-title">{{ max.itemName }}</h4>
                            <div class="roadtotal">
                                <div class="todaydata" id="pie1">
                                </div>
                                <div class="alldata" id="pie2">
                                </div>
                            </div>
                        </div>
                        <div class="content-right-background-img">
                            <h4 class="item-title">运动之星TOP10</h4>
                            <ul class="rankinglist rankinglist-bar" style="height: 0.5rem;padding-bottom:0;">
                                <li data-v-69f5bc2a="" class="item-title-bar" style="height: 0.5rem;">
                                    <span class="ranking-name"
                                          style="width: 10%;color: #00d8ff;font-size: 18px;">排名</span>
                                    <span class="ranking-name"
                                          style="width: 10%;color: #00d8ff;font-size: 18px;">头像</span>
                                    <span class="ranking-name"
                                          style="width: 20%;text-align:center;color: #00d8ff;font-size: 18px;">昵称 </span>
                                    <span class="ranking-name"
                                          style="width: 30%;text-align:center;color: #00d8ff;font-size: 18px;">运动总距离(公里)</span>
                                    <span class="ranking-name"
                                          style="width: 30%;text-align:center;color: #00d8ff;font-size: 18px;">运动总消耗(千卡)</span>
                                </li>
                            </ul>
                            <vue-seamless-scroll :data="userTop" :class-option="optionHover"
                                                 class="seamless-warp seamless-warp-top">
                                <ul class="rankinglist" style="height:60%;">
                                    <li v-for="(item,i) in userTop" style="height:18%;">
                                        <span style="width:10%;height:100%;display: inline-block;">{{ i + 1 }}</span>
                                        <span class="ranking-img"
                                              style="width:10%;height:100%;display: inline-block;"><img
                                                :src="item.headimg"/></span>
                                        <span class="ranking-name"
                                              style="width:20%;height:100%;text-align:center;display: inline-block;">{{
                                            item.nickname
                                            }}</span>
                                        <span class="ranking-name"
                                              style="width:30%;height:100%;text-align:center;display: inline-block;">{{
                                            item.distance
                                            }}</span>
                                        <span class="ranking-name"
                                              style="width:30%;height:100%;text-align:center;display: inline-block;">{{
                                            item.times
                                            }}</span>
                                    </li>
                                </ul>

                            </vue-seamless-scroll>
                        </div>
                        <div class="content-right-background-img" style="margin-bottom: 0">
                            <h4 class="item-title">步道运动数据</h4>
                            <div style="height: 85%;margin-top: -3%;" id="sportTrend">

                            </div>
<!--                            <vue-seamless-scroll :data="simples" :class-option="optionHover" class="seamless-warp">-->
<!--                                <ul class="roadnamelist">-->
<!--                                    <li v-for="(item,i) in simples">-->
<!--                                        <span>{{ i + 1 }}</span>-->
<!--                                        <span>{{ item.itemName }}</span>-->
<!--                                    </li>-->
<!--                                </ul>-->
<!--                            </vue-seamless-scroll>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </v-scale-screen>
</template>
<script>
// @ is an alias to /src
import {
    getBanner,
    getSimple,
    getMax,
    getFlow,
    getUserTop,
    getSexRadio,
    getFlowTrend,
    getItemWeather,
    getItemImg,
    getAgeInfoData,
    getSportInfoData
} from "@/api/api";
import Header from "../components/iframe/SingleHeader.vue";
import {trendbar} from "@/components/charts/trendbar";
import router from "@/router";
import {pie} from "@/components/charts/pie1";
import {column} from "@/components/charts/column";
import echarts from "echarts";
//import {china} from "@/components/charts/china.js";
//import {chinamap} from "@/components/charts/chinamap.js";
export default {
    data() {
        return {
            banners: [],
            simples: [],
            max: {},
            flow: {},
            userTop: [],
            allData: [],
            xdata: [],
            ydata: [],
            agexdata: [],
            ageydata: [],
            sportData: [],
            colorList: ["#2B59E0", "#00CAE2", "#3B4F74"],
            genderALL: [{
                label: "男",
                value: 0
            },
                {
                    label: "女",
                    value: 0
                }
            ],
            genderCurDay: [{
                label: "男",
                value: 0
            },
                {
                    label: "女",
                    value: 0
                }
            ],
            genderALLLable: ["男", "女"],
            genderCurDayLable: ["男", "女"],
            num: 0,
            nowDate: "",
            itemImg: "http://39.108.83.46/file/upload/roadmap_data.png",
            itemImg1: "",
            itemImg2: "",
            itemImg3: "",
            itemImg4: "",
            weatherData: {}
        };
    },
    name: "Home",
    components: {
        Header,
    },
    created() {
    },
    computed: {
        optionHover() {
            return {
                hoverStop: false
            }
        }
    },
    mounted() {
        let itemId = this.$route.params.itemId;
        let token = this.$route.params.token;
        const params = new URLSearchParams();
        params.append("token", token);
        this.getBanner(params);
        this.getSimple(params);
        this.getMax(params);
        this.getFlow(params);
        this.getUserTop(params);
        this.getSexRadio(params);
        this.getFlowTrend(params);
        this.getItemImg(params);
        this.getItemWeather(params);
        this.getAgeInfoData(params);
        this.getSportInfoData(params);
        setInterval(this.showTime, 1000);
    },
    methods: {
        getBanner(params) {
            getBanner(params).then((res) => {
                if (res.code === "API200") {
                    this.banners = res.data.bannerData.bannerList;
                } else if (res.code === "API300") {
                    router.push("/");
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        getSimple(params) {
            getSimple(params).then((res) => {
                if (res.code === "API200") {
                    this.simples = res.data.itemSimpleData.itemSimpleData;
                    this.num = res.data.itemCount;
                } else if (res.code === "API300") {
                    router.push("/");
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        getMax(params) {
            getMax(params).then((res) => {
                if (res.code === "API200") {
                    this.max = res.data.itemMax.itemMax;
                } else if (res.code === "API300") {
                    router.push("/");
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        getFlow(params) {
            getFlow(params).then((res) => {
                if (res.code === "API200") {
                    this.flow = res.data.flowData;
                } else if (res.code === "API300") {
                    router.push("/");
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        getUserTop(params) {
            getUserTop(params).then((res) => {
                if (res.code === "API200") {
                    this.userTop = res.data.top10User.top10User;
                } else if (res.code === "API300") {
                    router.push("/");
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        getSexRadio(params) {
            getSexRadio(params).then((res) => {
                if (res.code === "API200") {
                    let temp = res.data.sexData.sexAll;
                    if (temp[0].nums + temp[1].nums === 0) {
                        this.genderALL[0].label = temp[0].sexDesc + "性 0%"
                        this.genderALL[0].value = temp[0].nums
                        this.genderALLLable[0] = temp[0].sexDesc + "性 0%"
                        this.genderALL[1].label = temp[1].sexDesc + "性 0%"
                        this.genderALL[1].value = temp[1].nums
                        this.genderALLLable[1] = temp[1].sexDesc + "性 0%"
                    } else {
                        this.genderALL[0].label = temp[0].sexDesc + "性" + Math.floor(temp[0].nums / (temp[0].nums + temp[1].nums) * 100) + "%"
                        this.genderALL[0].value = temp[0].nums
                        this.genderALLLable[0] = temp[0].sexDesc + "性" + Math.floor(temp[0].nums / (temp[0].nums + temp[1].nums) * 100) + "%"
                        this.genderALL[1].label = temp[1].sexDesc + "性" + (100 - Math.floor(temp[0].nums / (temp[0].nums + temp[1].nums) * 100)) + "%"
                        this.genderALL[1].value = temp[1].nums
                        this.genderALLLable[1] = temp[1].sexDesc + "性" + (100 - Math.floor(temp[0].nums / (temp[0].nums + temp[1].nums) * 100)) + "%"
                    }

                    let temp1 = res.data.sexData.sexCurDay;

                    if (temp1[0].nums + temp1[1].nums === 0) {
                        this.genderCurDay[0].label = temp1[0].sexDesc + "性 0%"
                        this.genderCurDay[0].value = temp1[0].nums
                        this.genderCurDayLable[0] = temp1[0].sexDesc + "性 0%"
                        this.genderCurDay[1].label = temp1[1].sexDesc + "性 0%"
                        this.genderCurDay[1].value = temp1[1].nums
                        this.genderCurDayLable[1] = temp1[1].sexDesc + "性 0%"
                    } else {
                        this.genderCurDay[0].label = temp1[0].sexDesc + "性" + Math.floor(temp1[0].nums / (temp1[0].nums + temp1[1].nums) * 100) + "%"
                        this.genderCurDay[0].value = temp1[0].nums
                        this.genderCurDayLable[0] = temp1[0].sexDesc + "性" + Math.floor(temp1[0].nums / (temp1[0].nums + temp1[1].nums) * 100) + "%"
                        this.genderCurDay[1].label = temp1[1].sexDesc + "性" + (100 - Math.floor(temp1[0].nums / (temp1[0].nums + temp1[1].nums) * 100)) + "%"
                        this.genderCurDay[1].value = temp1[1].nums
                        this.genderCurDayLable[1] = temp1[1].sexDesc + "性" + (100 - Math.floor(temp1[0].nums / (temp1[0].nums + temp1[1].nums) * 100)) + "%"
                    }

                    this.drawPie1();
                    this.drawPie2();

                } else if (res.code === "API300") {
                    router.push("/");
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        getFlowTrend(params) {
            getFlowTrend(params).then((res) => {
                if (res.code === "API200") {
                    let temp = res.data.trendData.trend7Days;
                    for (let i = 0; i < temp.length; i++) {
                        this.xdata.push(temp[i].monthDay);
                        this.ydata.push(temp[i].nums);
                    }
                    this.drawTrendbar();
                } else if (res.code === "API300") {
                    router.push("/");
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        getItemImg(params) {
            getItemImg(params).then((res) => {
                if (res.code === "API200") {
                    let itemImages = res.data.itemImages;
                    if (itemImages != null) {
                        let length = itemImages.length;
                        if (length === 2) {
                            console.info(document.getElementById("image_default"))
                            this.itemImg1 = itemImages[0].itemImage;
                            this.itemImg2 = itemImages[1].itemImage;
                            document.getElementById("image_default").style.display = 'none';
                            document.getElementById("image_fourth").style.display = 'none';
                            document.getElementById("image_double").style.display = 'block';
                        }
                        else if (length === 3) {
                            this.itemImg1 = itemImages[0].itemImage;
                            this.itemImg2 = itemImages[1].itemImage;
                            this.itemImg3 = itemImages[2].itemImage;

                            document.getElementById("image_default").style.display = 'none';
                            document.getElementById("image_fourth").style.display = 'none';
                            document.getElementById("image_double").style.display = 'none';
                            document.getElementById("image_third").style.display = 'flex';
                        }
                        else if (length === 4) {
                            console.info(document.getElementById("image_default"));
                            console.info(document.getElementById("image_fourth"));
                            console.info(document.getElementById("image_double"));
                            this.itemImg1 = itemImages[0].itemImage;
                            this.itemImg2 = itemImages[1].itemImage;
                            this.itemImg3 = itemImages[2].itemImage;
                            this.itemImg4 = itemImages[3].itemImage;

                            document.getElementById("image_default").style.display = 'none';
                            document.getElementById("image_fourth").style.display = 'flex';
                            document.getElementById("image_double").style.display = 'none';
                        }
                    }
                    //this.itemImg = res.data.imageUrl ? res.data.imageUrl : 'https://image20210815.oss-cn-beijing.aliyuncs.com/itemimg/default.png';
                } else if (res.code === "API300") {
                    router.push("/");
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        getItemWeather(params) {
            getItemWeather(params).then((res) => {
                if (res.code === "API200") {
                    this.weatherData = res.data;
                } else if (res.code === "API300") {
                    router.push("/");
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        getAgeInfoData(params) {
            params.set("rangeDays", 7);
            getAgeInfoData(params).then((res) => {
                if (res.code === "API200") {
                    let list = res.data.list;
                    this.agexdata = [];
                    this.ageydata = [];
                    for (let i in list) {
                        this.agexdata.push(list[i].levelDesc);
                        this.ageydata.push(list[i].nums);
                    }
                    this.drawAgeTrendbar();
                } else if (res.code === "API300") {
                    router.push("/");
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        getSportInfoData(params) {
            getSportInfoData(params).then((res) => {
                if (res.code === "API200") {
                    this.sportDataDeal(res.data);
                    this.drawSportTrend();
                } else if (res.code === "API300") {
                    router.push("/");
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        drawTrendbar() {
            trendbar(
                document.getElementById("bdtrend"),
                this.xdata,
                this.ydata
            );
        },
        drawAgeTrendbar() {
            trendbar(
                document.getElementById("agetrend"),
                this.agexdata,
                this.ageydata
            );
        },
        sportDataDeal(data) {
            this.sportData = [];
            let dataTotalArray = [];
            let distanceArray = [];
            let kcalArray = [];
            for (let i = 0; i < data.length; i++) {
                let dataItem = data[i];
                let dataTime = dataItem.dataTime;

                let currDataTotalArray = [dataTime, dataItem.dataTotal];
                dataTotalArray.push(currDataTotalArray);

                let currDistanceArray = [dataTime, dataItem.distance];
                distanceArray.push(currDistanceArray);

                let currkcalArray = [dataTime, dataItem.kcal];
                kcalArray.push(currkcalArray);
            }

            this.sportData.push(dataTotalArray);
            this.sportData.push(distanceArray);
            this.sportData.push(kcalArray);
        },
        drawSportTrend() {
            let charts = echarts.init(document.getElementById("sportTrend"));
            let option = {
                // title: {
                //     text: "运动数据",
                //     left: "center",
                //     align: "right",
                //     textStyle: {
                //         color: "rgba(162,164,206,1)",
                //         fontSize: 36,
                //         fontWeight: 800,
                //     },
                // },
                // legend: {},
                xAxis: {
                    type: "category",
                    axisLabel: {
                        show: true,
                        interval: 0,
                        rotate: 30,
                        fontSize: 8,
                        fontWeight: 0,
                        color: "rgba(222,223,251,1)",
                        lineHeight: 5,
                    },
                    axisLine: {
                        lineStyle: {
                            color: "rgba(40,66,124,1)",
                        },
                    },
                    splitLine: {
                        lineStyle: {
                            color: "rgba(40,66,124,1)",
                        },
                    },
                },
                yAxis: {
                    type: "value",
                    // name: "运动量",
                    // nameLocation: "end",
                    // nameTextStyle: {
                    //     color: "rgba(222,223,251,1)",
                    //     fontSize: "10",
                    //     fontWeight: 5,
                    // },
                    splitNumber: 6,
                    splitLine: {
                        lineStyle: {
                            color: "rgba(40,66,124,1)",
                        },
                    },
                    axisLine: {
                        lineStyle: {
                            color: "rgba(40,66,124,1)",
                        },
                    },
                    axisLabel: {
                        show: true,
                        fontSize: 8,
                        fontWeight: 400,
                        color: "rgba(222,223,251,1)",
                        lineHeight: 5,
                    },
                },
                series: []

            };
            this.sportData.map((item, index) => {
                option.series.push({
                    type: "bar",
                    stack: "sport",
                    data: item,
                    barWidth: 10,
                    itemStyle: {
                        color: this.colorList[index],
                    },
                });
            });
            charts.setOption(option);
        },
        searchSevenOrFifteen(e) {
        },
        drawPie1() {
            pie(
                document.getElementById("pie1"),
                this.genderCurDay,
                this.genderCurDayLable,
                "当日"
            );
        },
        drawPie2() {
            pie(
                document.getElementById("pie2"),
                this.genderALL,
                this.genderALLLable,
                "全年"
            );
        },
        drawMap() {
            chinamap(
                document.getElementById("chinamap"),
                "",
                ""
            );
        },
        showTime() {
            let date = new Date();
            let weekText = "";
            switch (date.getDay()) {
                case 0:
                    weekText = "星期日";
                    break;
                case 1:
                    weekText = "星期一";
                    break;
                case 2:
                    weekText = "星期二";
                    break;
                case 3:
                    weekText = "星期三";
                    break;
                case 4:
                    weekText = "星期四";
                    break;
                case 5:
                    weekText = "星期五";
                    break;
                case 5:
                    weekText = "星期六";
                    break;
            }
            let yue = date.getMonth() + 1;
            let newDate = `${date.getFullYear()}年${yue === 13 ? '1' : yue}月${date.getDate()}日 ${weekText} ${date.getHours() < 10 ? "0" + date.getHours() : date.getHours()}: ${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}: ${
                date.getSeconds() < 10
                    ? "0" + date.getSeconds()
                    : date.getSeconds()
            }`;
            this.nowDate = newDate;
        },
    }
};
</script>

<style scoped lang="less">
@import "../assets/css/OverallStatus.less";

.seamless-warp {
    height: 80%;
    overflow: hidden;
}

.seamless-warp-top {
    height: 60%;
}
</style>
