<template>
    <div class="header">

        <h1 :class="titleName ? 'normal' : 'nomore'"><span class="updown-menu" @mouseover="showLayer()"></span>智能步道大数据平台
        </h1>
        <div class="header-date">
            <div>
                <span>{{ nowDate }}</span>
            </div>
        </div>
        <!--  弹出层开始-->
        <div class="updown-box-wrapper" :style="styleObject" @mouseleave="unshowLayer();">
            <div class="updown-box" id="updown-box">
                <!-- 遮罩层  <div class="updown-layer"></div>-->
                <div class="road-information">
                    <div class="list-title">
                        <span>序号</span>
                        <span>步道名称</span>
                        <span>步道客流量</span>
                        <span>步道访问人数</span>
                        <span>运动总距离</span>
                        <span>运动总消耗</span>
                        <span>运动总时长</span>
                    </div>
                    <div class="line"></div>
                    <ul class="list-item-data">
                        <li v-for="(item,i) in allData" @click="goHome(item.itemId);">
                            <span>{{ i + 1 }}</span>
                            <span>{{ item.itemName }}</span>
                            <span>{{ item.userFlow }}</span>
                            <span>{{ item.userDis }}</span>
                            <span>{{ item.distance }}</span>
                            <span>{{ item.times }}</span>
                            <span>{{ item.kcal }}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!--      弹出层结束-->
    </div>

</template>
<script>
import {getAllData, getglobalInfo, getItemToken, setItem} from "../../api/api.js";
import router from "@/router";

export default {
    data() {
        return {
            titleName: true,
            nowDate: "",
            title: "",
            xianshi: false,
            styleObject: "display:none",
            allData: []
        };
    },
    mounted() {
        /* 获取时间 */
        let timerId = setInterval(this.showTime, 1000);
        /* 获取title */
        const params = new URLSearchParams();
        let itemId = this.$route.params.itemId;
        let token = this.$route.params.token;
        params.append("token", token);
        this.getAllData(params);
        getglobalInfo(params).then((res) => {
            if (res.code === "API200") {
                this.title = res.data.itemName;
                if (this.title.length > 7) {
                    this.titleName = false;
                }
            } else if (res.code === "API300") {
                router.push("/");
            } else {
                this.$message.error(res.msg);
            }
        });
    },
    methods: {
        showTime() {
            let date = new Date();
            let weekText = "";
            switch (date.getDay()) {
                case 0:
                    weekText = "星期日";
                    break;
                case 1:
                    weekText = "星期一";
                    break;
                case 2:
                    weekText = "星期二";
                    break;
                case 3:
                    weekText = "星期三";
                    break;
                case 4:
                    weekText = "星期四";
                    break;
                case 5:
                    weekText = "星期五";
                    break;
                case 5:
                    weekText = "星期六";
                    break;
            }
            let yue = date.getMonth() + 1;
            let newDate = `${date.getFullYear()}年${yue === 13 ? '1' : yue}月${date.getDate()}日 ${weekText} ${date.getHours() < 10 ? "0" + date.getHours() : date.getHours()}: ${date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()}: ${
                date.getSeconds() < 10
                    ? "0" + date.getSeconds()
                    : date.getSeconds()
            }`;
            this.nowDate = newDate;
        },
        //弹出层
        showLayer() {
            this.styleObject = "display:block"
        },
        unshowLayer() {
            this.styleObject = "display:none"
        },
        goHome(itemId) {
            let token = this.$route.params.token;
            let permission = this.$route.query.permission;
            localStorage.setItem("singleToken", token);

            let path = '/home';
            if (permission !== undefined) {
                path = '/home?permission=' + permission;
            }
            this.$router.push({
                path: path,
            });
            // const params = new URLSearchParams();
            // params.append("token", localStorage.getItem("token"));
            // params.append("itemId", itemId);
            // setItem(params).then((res) => {
            //     if (res.code === "API200") {
            //         this.$router.push({
            //             path: '/home',
            //         });
            //     } else if (res.code === "API300") {
            //         router.push("/");
            //     } else {
            //         this.$message.error(res.msg);
            //     }
            // });
        },
        getAllData(params) {
            getAllData(params).then((res) => {
                if (res.code === "API200") {
                    this.allData = res.data.itemAllData.itemAllData;
                } else if (res.code === "API300") {
                    router.push("/");
                } else {
                    this.$message.error(res.msg);
                }
            });
        },

    },
};
</script>
<style scoped lang="less">
@import "../../assets/css/DashboardHeader.less";
</style>


